import React from 'react';
import { BsLinkedin, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href='https://www.linkedin.com/in/razvan-popescu-61b186265'>
      <BsLinkedin  />
      </a>
    </div>
    <div>
      <a href='https://www.facebook.com/popescu.razvan.735'>
      <FaFacebookF />
      </a>
      
    </div>
    <div>
      <a href='https://instagram.com/popescu.razvan.735?igshid=ZDdkNTZiNTM='>
      <BsInstagram />
      </a>
      
    </div>
  </div>
);

export default SocialMedia;