import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';


const About = () => {
  const [abouts, setAbouts] = useState([]);

  

  return (
    <>
    
      <h2 className="head-text">Crafting <span>Intuitive Interfaces</span> <br />For A Better  <span>Digital World</span></h2>

      <div className="app__profiles">
        <p className='about-text' style={{backgroundColor: 'lightgray',
    borderRadius: '40px',
    padding: '30px'
    }}>
      I am a skilled and motivated frontend developer with a passion for creating visually appealing and user-friendly web applications. With experience in HTML, CSS, JavaScript, and various frontend frameworks and libraries. I am confident in my ability to turn design mockups into fully functional and responsive websites. I stay up-to-date with the latest web development technologies and trends, and am always looking for new ways to improve my skills and the user experience. My greatest personal project till now is
       <a className='hangout-link' href='https://hangout-popescu-razvan.netlify.app/' > HANGOUT</a></p>
      
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);