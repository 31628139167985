import cert1 from '../assets/cert1.png';
import cert2 from '../assets/cert2.png';
import cert3 from '../assets/cert3.png';
import cert4 from '../assets/cert4.png';
import cert5 from '../assets/cert5.png';
import cert6 from '../assets/cert6.png';
import cert7 from '../assets/cert7.png';
import cert8 from '../assets/cert8.png';
import cert9 from '../assets/cert9.png';
import cert10 from '../assets/cert10.png';
import cert11 from '../assets/cert11.png';
import cert12 from '../assets/cert12.png';
import cert13 from '../assets/cert13.png';
import cert14 from '../assets/cert14.png';
import cert15 from '../assets/cert15.png';
import cert16 from '../assets/cert16.png';
import cert17 from '../assets/cert17.png';
import certif1 from '../assets/certif1.pdf';
import certif2 from '../assets/certif2.pdf';
import certif3 from '../assets/certif3.pdf';
import certif4 from '../assets/certif4.pdf';
import certif5 from '../assets/certif5.pdf';
import certif6 from '../assets/certif6.pdf';
import certif7 from '../assets/certif7.pdf';
import certif8 from '../assets/certif8.pdf';
import certif9 from '../assets/certif9.pdf';
import certif10 from '../assets/certif10.pdf';
import certif11 from '../assets/certif11.pdf';
import certif12 from '../assets/certif12.pdf';
import certif13 from '../assets/certif13.pdf';
import certif14 from '../assets/certif14.pdf';
import certif15 from '../assets/certif15.pdf';
import certif16 from '../assets/certif16.pdf';
import certif17 from '../assets/certif17.pdf';




export default [{
    image: cert1,
    name: 'Meta Frontend Developer',
    url: certif1},
    {image: cert2,
    name: 'Meta Version Control',
    url: certif2},
    {image: cert3,
    name: 'Meta Javascript',
    url: certif3},
    {image: cert4,
    name: 'Meta Advanced React',
    url: certif4},
    {image: cert5,
    name: 'Meta Interview Prep',
    url: certif5},
    {image: cert6,
    name: 'Meta UI/UX Design',
    url: certif6},
    {image: cert7,
    name: 'Meta Frontend Developer Capstone',
    url: certif7},
    {image: cert8,
    name: 'Meta React Basics',
    url: certif8},
    {image: cert9,
    name: 'Meta HTML/CSS in depth',
    url: certif9},
    {image: cert10,
    name: 'Meta Frontend Introduction',
    url: certif10},
    {image: cert11,
    name: 'Codecademy HTML course',
    url: certif11},
    {image: cert12,
    name: 'Codecademy CSS course',
    url: certif12},
    {image: cert13,
    name: 'Codecademy HTML, CSS, Github',
    url: certif13},
    {image: cert14,
    name: 'Codecademy Intermediate Javascript',
    url: certif14},
    {image: cert15,
    name: 'Codecademy Frontend Developer',
    url: certif15},
    {image: cert16,
    name: 'Jad Joubran Javascript course',
    url: certif16},
    {image: cert17,
    name: 'Jad Joubran React course',
    url: certif17},
    ];