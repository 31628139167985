import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { images } from '../../constants';

import { AppWrap, MotionWrap } from '../../wrapper';

import './Testimonial.scss';

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const [brands, setBrands] = useState([]);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  

  return (
    <>
      <div className="testimonial-container">
        <h1 className='test-header'>Testimonials</h1>
        <h2 className='test-subheader' style={{marginBottom:' 4rem'}}>People I've worked with have said some nice things...</h2>
        <div className="testimonial__content">
          <div className='fig-wrapper'  style={{marginBottom:' 4rem'}}>
          <figure>
          <img src={images.testceo} alt='testimonial logo' id='testlogo'/>
            <figcaption>Radu Cristian, CEO</figcaption>
          </figure>
          <figure>
          <img src={images.testlogo} alt='testimonial logo' id='testlogo'/>
            <figcaption> Project Web Global</figcaption>
          </figure>
      
          </div>
          
          
          
          <h3 className='test-quote'>“Răzvan is an excellent junior frontend developer who brings a strong work ethic, attention to detail, and passion for frontend development to the team. He quickly became an integral member, producing clean, responsive, and user-friendly web applications. His willingness to take on new challenges and learn new technologies makes him an asset to any team. Highly recommended.”</h3>


          </div>
          
      </div>
    
          
      
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, 'app__testimonial'),
  'testimonial',
  'app__whitebg',
);