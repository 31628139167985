import proj1 from "../assets/proj1.png";
import proj2 from "../assets/proj2.png";
import proj3 from "../assets/proj3.png";
import proj4 from "../assets/proj4.png";
import proj5 from "../assets/proj5.png";
import proj6 from "../assets/proj6.png";
import proj7 from "../assets/proj7.png";
import proj8 from "../assets/proj8.png";
import proj9 from "../assets/proj9.png";
import proj10 from "../assets/proj10.png";


export default [
  {
    image: proj9,
    name: "Hangout MERN App",
    url: "https://hangout-popescu-razvan.netlify.app/",
  },
  // {
  //   image: proj10,
  //   name: "Betting Odds App",
  //   url: "https://betting-odds-project-razvan.netlify.app/",
  // },
  {
    image: proj1,
    name: "React Admin Dashboard App",
    url: "https://dashboard-react-syncfusion-project.netlify.app/",
  },
  {
    image: proj2,
    name: "React Restaurant Website",
    url: "https://gericht-restaurant-reactapp.netlify.app/",
  },
  {
    image: proj3,
    name: "HTML,CSS,JS Jiu Jitsu Website",
    url: "https://next-generation-combat.netlify.app/",
  },


  {
    image: proj6,
    name: "TodoList App",
    url: "https://popescurazvan-react-todolist.netlify.app/",
  }



 


];
