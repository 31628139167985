import React, { useState, useEffect } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import ProjectItem from  './ProjectItem';

import './Work.scss';
import { images } from '../../constants';
import {projects} from '../../constants';

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

 

  

  return (
    <>
      
      <div className="projects">
      <h2 className="head-text">My Creative <span>Portfolio</span> Section</h2>
      <div className="projectList">
        {projects.map((project, idx) => {
          return (<a href={project.url} style={{textDecoration: 'none'}}>
            <ProjectItem id={idx} name={project.name} image={project.image} />
            </a>
            
          );
        })}
      </div>
    </div>
   

      
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, 'app__works'),
  'work',
  'app__whitebg',
);