import React from "react";


function ProjectItem({ image, name, id }) {
  const isNew = name === 'Hangout MERN App' ; // Check if the project is one of the first two

  return (
    <div className="projectItem" style={{ backgroundColor: '#e4e4e4', position: 'relative' }}>
      {isNew && (
        <span style={{
          position: 'absolute',
          top: '-5px',
          left: '-5px',
          backgroundColor: 'green',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          fontSize: '1.1em'
        }}>
          New
        </span>
      )}
      <div style={{ backgroundImage: `url(${image})` }} className="bgImage" />
      <h1>{name}</h1>
    </div>
  );
}

export default ProjectItem;
