import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { AppWrap, MotionWrap } from '../../wrapper';
import certificates from '../../constants/certificates';
import ProjectItem from '../Work/ProjectItem';

import './Skills.scss';

const Skills = () => {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);

  
  return (
    <>
      <div className="projects">
      <h2 className="head-text">My Creative <span>Portfolio</span> Section</h2>
      <div className="projectList">
        {certificates.map((project, idx) => {
          return (<a href={project.url} style={{textDecoration: 'none'}}>
            <ProjectItem id={idx} name={project.name} image={project.image} />
            </a>
            
          );
        })}
      </div>
    </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);